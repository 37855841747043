import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Traec from "traec";

import { ErrorBoundary } from "traec-react/errors";
import { authFailed } from "traec-react/auth/utils";
import { setNavBarItems } from "traec-react/navBar";

import CompanyInvites from "./companyInvite";
import UserCompany from "./company";
import ProjectInvites from "./projectInvite";

import UserProjects from "traec-react/user/userProjects/project";
import { RelatedRefs } from "AppSrc/user/projectItem/relatedRefs";
import RelatedCommits from "AppSrc/user/projectItem/relatedCommits";

import UserSetupModal from "./userSetupModal";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(setNavBarItems(this.navbarLinks()));
  }

  navbarLinks() {
    return [{ label: " ", octicon: "home", to: "/" }];
  }

  render() {
    let { authFailed, user } = this.props;
    if (authFailed) {
      return <Redirect to="/accounts/login?next=/accounts/profile/" />;
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h3>Account overview</h3>
            <p>
              Explore the dashboards and access reporting of your account by selecting from the list below.
            </p>
          </div>
        </div>
        <div className="row">
          <ErrorBoundary>
            <CompanyInvites />
          </ErrorBoundary>
        </div>
        <div className="row">
          <ErrorBoundary>
            <ProjectInvites />
          </ErrorBoundary>
        </div>
        <div className="row" style={{display: "none"}}>
          <ErrorBoundary>
            <UserProjects
              title={"My Projects and Reports"}
              redirectIfOne={!(user || Traec.Im.Map()).get("is_tenant_superuser")} // Redirect if the user is not a superuser and only has one Reporting Package  !(user || traec.Im.Map()).get('is_tenant_superuser')
            >
              <RelatedRefs />
              <RelatedCommits />
            </UserProjects>
          </ErrorBoundary>
        </div>
        <div className="row">
          <ErrorBoundary>
            <UserCompany />
          </ErrorBoundary>
        </div>

        <UserSetupModal />
      </div>
    );
  }
}

UserProfile.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  authFailed: authFailed(state),
  user: state.getInPath(`auth.user`)
});

export default connect(mapStateToProps)(UserProfile);
