import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BSCard, BSBtn } from "traec-react/utils/bootstrap";
import CompanyItem from "./companyItem";
import { isSuperuser } from "traec-react/utils";
import useApi, { fetcher } from "storybook-dashboard/utils/fetching";
import { BasicSpinner } from "storybook-dashboard/spinners/basicSpinner";
import { Modal, useModal } from "storybook-dashboard/components/modal";
import Im from "immutable";

const formEventToData = (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  return Object.fromEntries(formData.entries());
};

const createCompany = (e, onSuccess) => {
  let data = formEventToData(e);
  console.log("Creating new company with data", data);
  fetcher("/api/company/", "POST", data).then(({ payload: data }) => {
    console.log("Success creating company", data?.toJS());
    onSuccess();
  });
};

function FormInput({ name, placeholder }) {
  return (
    <div className="form-group">
      <input className="form-control" name={name} placeholder={placeholder} />
    </div>
  );
}

function AddCompanyModal() {
  let { trigger } = useApi("/api/company/");
  let { setModal, hideModal } = useModal();
  const onSuccess = () => {
    trigger();
    setModal(<Modal></Modal>);
    hideModal();
  };
  return (
    <Modal title="Create a new root company account">
      <form className="form" onSubmit={(e) => createCompany(e, onSuccess)}>
        <FormInput name="name" placeholder="Company name" />
        <FormInput name="domain" placeholder="Company domain" />
        <button className="btn btn-sm btn-outline-primary float-right" type="submit">
          Create company
        </button>
      </form>
    </Modal>
  );
}

function AddCompanyButton({ user }) {
  let { setModal } = useModal();
  if (!isSuperuser(user)) {
    return null;
  }
  return <BSBtn onClick={() => setModal(<AddCompanyModal />)} text="Create a Company" />;
}

export function UserCompanies() {
  const [formParams, setFormParams] = useState({ initFields: {} });
  const user = useSelector((state) => state?.getInPath("auth.user"));
  let { data: companies, isLoading } = useApi("/api/company/");
  if (isLoading) {
    return <BasicSpinner />;
  }

  if (!companies || !companies.size) {
    return <p className="text-center">No companies to display</p>;
  }

  let itemList = companies
    .toList()
    .sortBy((obj) => obj?.get("created"))
    .filter((obj) => obj?.get("parentid") == null)
    .map((item, i) => (
      <CompanyItem
        key={i}
        index={i}
        indentLevel={0}
        company={item}
        companiesById={companies?.reduce((a, i) => a.set(i.get("uid"), i), Im.Map())}
        user={user}
      />
    ));

  return (
    <BSCard id="user-companies" widthOffset="col-sm-12" button={<AddCompanyButton user={user} />} body={itemList} />
  );
}

export default UserCompanies;
