import React from "react";
import { connect } from "react-redux";
import Traec from "traec";

const colors = {
  emcore: "FF3D00"
};

export function DynamicStyle(props) {
  let { navBarColor, headerColor, buttonColor } = props;

  console.log("LOADED COLORS FROM STYLE", props);

  // For testing only
  //headerColor = "#FF6100";
  //buttonColor = "#FF8500";
  //navBarColor = "#666666";

  let text = "";

  if (headerColor) {
    text += `
.page-sidebar { background-color: ${headerColor}; }
.footer { background-color: ${headerColor}; }
.card-header { background-color: ${headerColor}; }
`;
  }

  if (buttonColor) {
    text += `
.btn-primary { background-color: ${buttonColor}; border-color: ${buttonColor} }
.btn-primary:disabled { background-color: ${buttonColor}; border-color: ${buttonColor} }
`;
  }

  if (navBarColor) {
    text += `
.navbar-light { background-color: ${navBarColor} !important; }
`;
  }

  if (!text) {
    return null;
  }

  return <style>{text}</style>;
}

const mapStateToProps = (state, ownProps) => {
  let styles = state.getInPath("ui.styles") || Traec.Im.Map();
  let _props = ["navBarColor", "headerColor", "buttonColor"].reduce((acc, cur) => {
    acc[cur] = styles.get(cur);
    return acc;
  }, {});
  return { ..._props };
};

export default connect(mapStateToProps)(DynamicStyle);
