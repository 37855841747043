import React from "react";
import ReactDOM from "react-dom";

import Traec from "traec";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import { ActionButton } from "./button";
import { alertSuccess } from "traec-react/utils/sweetalert";

const respondInvite = (invite, accept) => {
  let companyId = invite.getIn(["company", "uid"]);
  let inviteId = invite.get("uid");
  let fetch = new Traec.Fetch("company_invite_all", "patch", { companyId, inviteId });
  fetch.updateFetchParams({
    preFetchHook: body => ({
      accepted: accept,
      company: { uid: companyId }
    })
  });
  fetch.dispatch();
};

function AcceptRejectButtons({ invite, isRequest }) {
  let inviteId = invite.get("uid");
  if (isRequest) {
    return (
      <React.Fragment>
        <ActionButton
          text="Awaiting approval"
          onClickHandler={e => {
            alertSuccess({
              title: "Requires approval from colleagues",
              text: `This request is pending approval from a colleague.  
                Contact your colleague or support for assistance in approving this request.`,
              iconType: "warning"
            });
          }}
        />
        <ActionButton
          color="danger"
          text="Delete"
          onClickHandler={e => {
            new Traec.Fetch("company_invite_all", "delete", { inviteId }).dispatch();
          }}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ActionButton color="success" text="accept" onClickHandler={e => respondInvite(invite, true)} />
      <ActionButton color="danger" text="reject" onClickHandler={e => respondInvite(invite, false)} />
    </React.Fragment>
  );
}

export default function CompanyInviteItem(props) {
  let { index: i, invite } = props;
  if (!invite) {
    return null;
  }
  return (
    <div className="row" key={i} style={{ backgroundColor: (i + 1) % 2 ? "#ddd" : "" }}>
      <div className="col-sm-6">{invite.getIn(["company", "name"])}</div>
      <div className="col-sm-3">{invite.getIn(["auth", "name"])}</div>
      <div className="col-sm-3">
        <div className="row">
          <AcceptRejectButtons {...props} isRequest={invite.get("is_request")} />
        </div>
      </div>
    </div>
  );
}
