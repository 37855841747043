import React from "react";
import LoginForm from "traec-react/auth/form";
import { BSCard } from "traec-react/utils/bootstrap";
import Octicon from "react-octicon";
import { getAzureConfig } from "../app-router";
import "./static.css";
import { YoutubeEmbed } from "./youtubeEmbed";

function Section({ children }) {
  return (
    <section className="home-section">
      <div className="container">
        <div className="row">{children}</div>
      </div>
    </section>
  );
}

function TitleSection() {
  return (
    <div className="container-fluid m-0 p-0">
      <div className="row" style={{ borderBottom: "1px solid blue" }}>
        <div className="col-sm-6 d-flex justify-content-center align-items-center">
          <h2>
            <b>Annual diversity survey</b>
          </h2>
        </div>
        <div className="col-sm-6 text-center">
          <img
            src="https://github.com/ActionSustainability/static-content/blob/master/nationalhighways/IcgMpNatHighwaysLogo.png?raw=true"
            alt=""
            style={{ maxWidth: "100%" }}
            className="img-fluid m-0 p-0"
          />
        </div>
      </div>
    </div>
  );
}

function HeaderArea() {
  return (
    <div class="home3 home3 d-flex justify-content-center py-5">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-5">
            <div class=" text-center">
              <h1 class="display-2 font-weight-bold pb-25 ">
                How
                <span className="display-1 font-weight-bold mx-2" style={{ color: "#5d5fef" }}>
                  diverse
                </span>
                is your company?
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const HomeScreenImageCard = ({ imageUrl, redirectUrl, imageAlt, title }) => {
  return (
    <div className="col-sm-12 col-md-4">
      <div className="card shadow p-3 mb-5 bg-white rounded border-0" style={{ cursor: "pointer" }}>
        <img
          src={`${imageUrl}`}
          className="card-image-top"
          style={{ filter: "brightness(50%)", minHeight: "250px", width: "100%", objectFit: "cover" }}
          alt={imageAlt ? `${imageAlt}` : "Sustainability tool image"}
        />
        <a href={`${redirectUrl}`} className="btn btn-sm btn-outline-primary mt-3" target="_blank">
          <div className="card-img-overlay d-flex justify-content-center align-items-center">
            <h4 className="card-title text-white text-center">{`${title}`}</h4>
          </div>
          Learn more
        </a>
      </div>
    </div>
  );
};

function RegisterSection({ isAuthenticated }) {
  return (
    <div className="container">
      <h2 className="my-5 py-5 text-center"></h2>
      {/* Register portal below */}
      <div className="row">
        <HomeScreenImageCard
          title="Measure and report your organisation's diversity data"
          redirectUrl={
            "https://landing.sustainabilitytool.com/Sustainability-tool--Diversity-survey/suppliermicrosite.html"
          }
          imageUrl={
            "https://github.com/ActionSustainability/static-content/blob/master/carbonCalculator/towersImage.png?raw=true"
          }
        />
        <HomeScreenImageCard
          title="Measure your supply chain diversity"
          redirectUrl={
            "https://landing.sustainabilitytool.com/Sustainability-tool--Diversity-survey/diversitysurveyclientmicrosite.html"
          }
          imageUrl={
            "https://github.com/ActionSustainability/static-content/blob/master/carbonCalculator/cargoImage.png?raw=true"
          }
        />
        <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center">
          {isAuthenticated || isAuthenticated == "confirmed" ? (
            <BSCard
              title="Already logged in"
              widthOffset="col-sm-12 p-0"
              body={
                <div>
                  <p>You are already logged in</p>
                  <button className="btn btn-primary btn-sm" onClick={() => (location.href = "/accounts/profile")}>
                    Access account
                  </button>
                </div>
              }
            />
          ) : (
            <BSCard
              title="Log in"
              widthOffset="col-sm-12 p-0"
              body={<LoginForm createText={"Create a supplier account"} azureConfig={getAzureConfig()} />}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function FlowBox({ text, arrow = true }) {
  return (
    <React.Fragment>
      <div className="home-flow-box text-center text-white">
        <span>
          <b>{text}</b>
        </span>
      </div>
      {arrow ? <Octicon name="arrow-right" mega={true} className="pl-2" /> : null}
    </React.Fragment>
  );
}

function RegisterFlowDiagram() {
  return (
    <Section>
      <h3>
        <b>3 Easy Steps</b>
      </h3>
      <div className="col-sm-12 ml-0 mr-0 mt-4 d-flex flex-row justify-content-center align-items-center">
        <FlowBox text="Create Account" />
        <FlowBox text="Complete Survey" />
        <FlowBox text="View Analysis Dashboards" arrow={false} />
      </div>
    </Section>
  );
}

function StepSection({ title, text, imgSrc, nextArrow = true }) {
  return (
    <Section>
      <div className="col-sm-7">
        <h3>
          <b>{title}</b>
        </h3>
        {text}
      </div>
      <div className="col-sm-5">
        <img src={imgSrc} alt="" style={{ maxWidth: "100%" }} className="img-fluid m-0 p-0" />
      </div>
      {nextArrow ? (
        <div className="col-sm-12 mt-5 d-flex justify-content-center" style={{ borderTop: "1px dashed blue" }}>
          <Octicon name="arrow-down" className="text-primary home-down-arrow" />
        </div>
      ) : null}
    </Section>
  );
}

export function StaticContent(props) {
  return (
    <div>
      <RegisterSection {...props} />
    </div>
  );
  return (
    <div className="designed-homepage">
      <HeaderArea />
      <RegisterSection {...props} />
      <DesignedHomepage />
      <>
        {/* <TitleSection /> */}
        {/* <RegisterFlowDiagram /> */}
        {/* <hr className="mt-5 mb-5" /> */}
        {/*<Section>
        <h3>
        <b>How to Complete the Survey</b>
        </h3>
        <div className="embed-responsive embed-responsive-16by9">
          <br></br>
          <iframe
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/LAUV08sQr0M"
            title="Diversity Benchmarking Survey"
            style={{ border: "1px solid black" }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <br></br>
        <div className="col-sm-12 mt-5">
          <br></br>
          <h3>
            {" "}
            <b>Benefits of the Diversity Tool’s Survey? </b>
          </h3>
          <ul>
            <li>Online standardised diversity measuring and monitoring system</li>
            <li>Data aggregation and analysis at an organisational, supply chain and industry level</li>
            <li>Annual Industry benchmarks and comparison against ONS census data</li>
            <li>
              Smart dashboards allow you to track year-on-year changes and trends of your own workforce and for your
              sector
            </li>
            <li>FREE for the supply chain</li>
            <li>
              The data set has grown from 22,000 to 251,000+ employees over 5 years, and so is the largest data set in
              the industry
            </li>
          </ul>
          <br></br>
          <img className="col-sm mt-5" src="/assets/images/logo/diversity-2021-benchmarks-stats.png"></img>
          <br></br>
          <br></br>
          <h3 className="mt-5">
            <b>Webinars</b>
          </h3>
          <h5>Reporting Webinars (click to register):</h5>
          <a
            target="_blank"
            href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=8305&modtype=tlevent"
            className="text-primary"
          >
            Thursday, 28th July 2022, 11am-12pm
          </a>
          <br></br>
          <a
            target="_blank"
            href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=8313&modtype=tlevent"
            className="text-primary"
          >
            Wednesday, 24th August 2022, 12pm-1pm
          </a>
          <br></br> <br></br>
          <h5>Dashboard interpretation Webinars (registration available soon):</h5>
          <a className="text">Thursday, 20th October 2022, 11am-12pm</a>
          <br></br> <br></br>
          <h3>
            <b>Contact </b>
          </h3>
          <p>
            <a
              href="mailto:olivia.banfield@actionsustainability.com?subject=Diversity Tool Contact Us"
              target="_blank"
              className="text-primary"
            >
              <u>olivia.banfield@actionsustainability.com</u>
            </a>
          </p>
        </div>
      </Section> */}
      </>
    </div>
  );
}

/* ################### */

function DescriptionColumn(props) {
  let { cellTitle, description } = props;
  return (
    <div className="info-descr eq-height">
      <div className="tbl-cell">
        <h2 itemProp="name">
          <span className="icon">
            <em className="fa fa-bar-chart"></em>
          </span>
          {cellTitle}
        </h2>
        {description}
      </div>
    </div>
  );
}

function HomeSection(props) {
  let { imagecol } = props;
  return (
    <section
      style={{ paddingTop: "5em" }}
      className="info-section home"
      id="section-01"
      itemScope
      itemType="http://schema.org/Service"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-5">{imagecol}</div>
          <div className="col-md-6 col-sm-7">
            <DescriptionColumn {...props} />
          </div>
        </div>
      </div>
    </section>
  );
}

function WhatIsDescription(props) {
  return (
    <React.Fragment>
      <p itemProp="description">
        The Sustainability Tool is a cloud based sustainability performance reporting system, which has been
        specifically designed &nbsp;to be used for supply chain management. It enables you to collect, report &amp;
        analyse social &amp; environmental sustainability performance using smart dashboards at project, business unit
        and corporate level.
      </p>
      <p itemProp="description">
        <a href="/contact/">Contact us to find out more about how the tool can help you&nbsp;&gt;</a>
      </p>
    </React.Fragment>
  );
}

function WhatIs(props) {
  return (
    <HomeSection
      cellTitle={"What is our tool?"}
      description={<WhatIsDescription />}
      imagecol={
        <iframe
          style={{ width: "100%" }}
          height="255"
          src="https://www.youtube.com/embed/UbGPrVfJgKY"
          frameBorder="0"
        ></iframe>
      }
    />
  );
}

function HowWorksDescription() {
  return (
    <React.Fragment>
      <p itemProp="description">
        View and manage performance at corporate, business unit and project level through smart dashboards and
        infographics. The tool is&nbsp;designed to mimic your supply chain and project management.&nbsp;
        <br />
        <br />
        At project level, the tool is designed to be used post-award of a contract.&nbsp;You choose what to measure, and
        delegate to your supply chain to report. You then view their performance in real time, allowing you to act
        quickly on &nbsp;any issues that arise.
        <br />
        <br />
        At business unit and corporate level, you can see the issues that your business are not performing well on, but
        also see the areas you are excelling on.
        <br />
        <br />
        <a href="/how/">Find out more about how the tool works &gt;</a>
      </p>
    </React.Fragment>
  );
}

function HowWorks(props) {
  return (
    <HomeSection
      descrRight={true}
      cellTitle={"How the tool works"}
      description={<HowWorksDescription />}
      imagecol={<img alt="" src="/assets/images/home/1BB.jpg" />}
    />
  );
}

function WhyUseDescription() {
  return (
    <React.Fragment>
      <p itemProp="description">
        You need to understand how your supply chain is working towards key sustainability targets.
        <br />
        <br />
        The Sustainability Tool is a one stop platform that allows you to monitor and manage the impact of your
        projects, without getting bogged down in spreadsheets and time consuming data collection.&nbsp;
        <br />
        <br />
        <a href="/benefits/">Find out more about the business benefits of the tool &gt;</a>
      </p>
    </React.Fragment>
  );
}

function WhyUse() {
  return (
    <HomeSection
      cellTitle={"Why use the tool"}
      description={<WhyUseDescription />}
      imagecol={
        <iframe
          style={{ width: "100%" }}
          height="255"
          src="https://www.youtube.com/embed/KOhK7UfFP4A"
          frameBorder="0"
        ></iframe>
      }
    />
  );
}

function DesignedHomepage() {
  const clientImages = [
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/CADENT%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/ENVIROMENT%20AGENCY%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/ICG%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/MAJOR%20PROJECTS%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/MORGAN%20SINALL%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/NATIONAL%20HIGHWAYS%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/NETWORK%20RAIL%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/SCSS%20LOGO.svg",
    "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/clients/TFL%20LOGO.png?raw=true",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/cb69b4d2aae3163ed83c894960324b5415bb16b3/diversityTool/designedHomepage/clients/VOLKER%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/52a8cd56fbc2d59d956fd59a33809613491edbb5/diversityTool/designedHomepage/clients/RICS%20LOGO.svg",
    "https://raw.githubusercontent.com/ActionSustainability/static-content/52a8cd56fbc2d59d956fd59a33809613491edbb5/diversityTool/designedHomepage/clients/HS2%20LOGO.svg"
  ];
  const firIcons = [
    {
      name: "Disability",
      image:
        "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/icon/icon-1.png?raw=true",
      resource: "https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=5928&modtype=page"
    },
    {
      name: "Gender",
      image:
        "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/icon/icon-2.png?raw=true",
      resource: "https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=9751&modtype=url"
    },
    {
      name: "Sexual Orientation",
      image:
        "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/icon/icon-3.png?raw=true",
      resource: "https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=1733&modtype=resource"
    },
    {
      name: "Ethnicity",
      image:
        "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/icon/icon-4.png?raw=true",
      resource: "https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=3456&modtype=resource"
    },
    {
      name: "Age",
      image:
        "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/icon/icon-5.png?raw=true",
      resource: ""
    },
    {
      name: "Religion",
      image:
        "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/icon/icon-6.png?raw=true",
      resource: "https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=1685&modtype=url"
    },
    {
      name: "Pay Gap",
      image:
        "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/icon/icon-7.png?raw=true",
      resource: "https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=1898&modtype=url"
    },
    {
      name: "Supply Chain",
      image:
        "https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/icon/icon-8.png?raw=true",
      resource: "https://www.supplychainschool.co.uk/topics/fir/supply-chain/"
    }
  ];
  return (
    <>
      <Section>
        <div class="brand-area home3 brand-height over-hidden">
          <div class="container py-5">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="service-content mt-60">
                <div class="title text-center mb-40">
                  <h3 class="f-700">
                    The industry's largest <br /> Diversity data set
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-mg-12">
              <div class="text-center">
                <img
                  src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/ShowcasingDiversity.png?raw=true"
                  class="img-fluid"
                  alt="ShowcasingDiversityCollection"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div class="container py-5">
          <div class="col-md-12">
            <div class="title text-center">
              <h4 class="f-700">Categories</h4>
              <p>The diversity survey captures data covering the protected characteristics listed below</p>
            </div>
          </div>
          <div class="row">
            <div class="col-mg-12">
              <div class="category-img-1 text-center">
                <img
                  src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/IconsOfCategories.png?raw=true"
                  class="img-fluid"
                  alt="IconsOfCategories"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-mg-12">
              <div class="category-img-2 text-center">
                <img
                  src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/DataInsightsGraphic.png?raw=true"
                  class="img-fluid"
                  alt="DataInsightsGraphic3"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        {/* Partners Section */}
        <div class="brand-area home3 brand-height over-hidden">
          <div class="container py-5 text-center">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="service-content mt-60">
                  <div class="title text-center">
                    <h3 class="f-700 mb-40">Our partners</h3>
                    <p>Industry experts leading diversity data benchmarking</p>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row d-flex justify-content-center">
                  {clientImages.map((image, index) => (
                    <div className="col-6 col-sm-6 col-md-2" key={index}>
                      <img src={image} alt={`Image ${index}`} className="img-fluid" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Become a Partner Section */}
            <div class="row pb-5">
              <div class="col-md-12">
                <div class="partner-cta d-flex justify-content-between">
                  <p>
                    Want to lead on diversity & benchmark your supply chain? <br /> Contact us for more information on
                    our partner package
                  </p>
                  <div class="my-btn">
                    <a
                      href="mailto:charles.naud@actionsustainability.com?subject=Becoming a Partner of the Diversity Tool"
                      target="_blank"
                      class="btn theme-bg text-capitalize f-18 f-700"
                    >
                      Become A Partner
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-mg-12">
                <div class="category-img-2 text-center">
                  <div class="title text-center">
                    <h3 class="f-700 mb-40">Why complete the Diversity Survey?</h3>
                  </div>
                  <img
                    src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/FinalDiversityBenefits.png?raw=true"
                    class="img-fluid"
                    alt="DiversityBenefitsSurvey"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <>
        {/* FAQ Section */}
        <div class="faq-document-area pt-0">
          <div class="container py-5">
            <div className="col-sm pb-5">
              <div className="row d-flex justify-content-center">
                <h4 className="f-700 pb-4">Support documents</h4>
              </div>
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <p className="f-800 align-self-center pt-3">How to register</p>
                  <YoutubeEmbed link="https://www.youtube.com/embed/utOLlwBujnM" title="How to register" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <p className="f-800 align-self-center pt-3">How to report</p>
                  <YoutubeEmbed link="https://www.youtube.com/embed/9eCMsVqgrzo" title="How to report" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <p className="f-800 align-self-center pt-3">Navigating your dashboard</p>
                  <YoutubeEmbed link="https://www.youtube.com/embed/jfHZVzvf1LA" title="Navigating your dashboard" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="faq-box">
                  <h5>FAQ document:</h5>
                  <p>
                    Have some questions about reporting your data? Download our FAQ document now for all the answers you
                    need!
                  </p>
                  <div class="my-btn">
                    <a
                      href="https://github.com/ActionSustainability/static-content/raw/master/diversityTool/designedHomepage/files/FAQ%202023.xlsx"
                      class="btn theme-bg text-capitalize f-18 f-700"
                    >
                      Download our FAQ document
                    </a>
                  </div>
                </div>
                <div class="faq-box">
                  <h5>Metric list for the full reporting option:</h5>
                  <p>
                    If your company has over 250 employees, download our PDF of the full reporting option's metric list.
                  </p>
                  <div class="my-btn">
                    <a
                      href="https://github.com/ActionSustainability/static-content/raw/master/diversityTool/designedHomepage/files/FULL%20metrics%20list%20Diversity%20Survey%20%2B250%20employees.xlsx"
                      class="btn theme-bg text-capitalize f-18 f-700"
                    >
                      Download our PDF
                    </a>
                  </div>
                </div>
                <div class="faq-box">
                  <h5>Metric list for the lite reporting option:</h5>
                  <p>
                    If your company has less than 250 employees, download our PDF of the lite reporting option's metric
                    list.
                  </p>
                  <div class="my-btn">
                    <a
                      href="https://github.com/ActionSustainability/static-content/raw/master/diversityTool/designedHomepage/files/LITE%20Diversity%20Survey%20metrics%20-250employees.xlsx"
                      class="btn theme-bg text-capitalize f-18 f-700"
                    >
                      Download our PDF
                    </a>
                  </div>
                </div>
                <div class="faq-box">
                  <h5>The duplicable survey:</h5>
                  <p>
                    Don't have the data you need to report? Download our duplicable survey to support you in collecting
                    the necessary data anonymously.
                  </p>
                  <div class="my-btn">
                    <a
                      href="https://forms.office.com/Pages/ShareFormPage.aspx?id=msmPhXkeuUyEEOKJDX3su9Cp3qUETxRPlVA6tDNe6TJUNVBDQVU4R1ZXS1JQN1I1T0NISE9ONUZHTy4u&sharetoken=YblW2WeEi84HT0vkDXDy"
                      target="_blank"
                      class="btn theme-bg text-capitalize f-18 f-700"
                    >
                      Access the duplicable survey
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        {/* Webinars Section */}
        <div class="blog-area home3 py-5">
          <div class="container py-5">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-7 col-md-10 col-sm-10 col-12">
                <div class="title text-center">
                  <h4 class="f-700">Support sessions</h4>
                  <p>Sign Up for Our Webinars - Learn More and Register Here</p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center home3-blog-wrapper">
              <div class="col-xl-4 col-lg-6  col-md-6 col-sm-10 col-12 py-2">
                <div class="single-blog mb-30 zoom-img-hover">
                  <div class="blog-img over-hidden">
                    <img
                      class="w-100 img"
                      src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/blog/blog-1.png?raw=true"
                      alt="blog1"
                    />
                  </div>
                  <div class="single-blog-content">
                    <span class="date d-block mb-1">Thurs 25 may – 11:00 – 11:45</span>
                    <h4>
                      <a
                        target="_blank"
                        href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=9642&modtype=tlevent"
                      >
                        Diversity Survey 2023 – How to report your organisation’s diversity data: webinar
                      </a>
                    </h4>
                    <div class="my-btn pt-20">
                      <a
                        target="_blank"
                        href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=9642&modtype=tlevent"
                        class="btn theme-bg text-capitalize f-18 f-700"
                      >
                        Register Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-6  col-md-6 col-sm-10 col-12 py-2">
                <div class="single-blog mb-30 zoom-img-hover">
                  <div class="blog-img over-hidden">
                    <img
                      class="w-100 img"
                      src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/blog/blog-2.png?raw=true"
                      alt="blog2"
                    />
                  </div>
                  <div class="single-blog-content">
                    <span class="date d-block mb-1">Thurs 22 June – 11:00 – 11:45</span>
                    <h4>
                      <a
                        target="_blank"
                        href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=9644&modtype=tlevent"
                      >
                        Diversity Survey 2023 – How to report your organisation’s diversity data: webinar
                      </a>
                    </h4>
                    <div class="my-btn pt-20">
                      <a
                        target="_blank"
                        href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=9644&modtype=tlevent"
                        class="btn theme-bg text-capitalize f-18 f-700"
                      >
                        Register Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-6  col-md-6 col-sm-10 col-12 py-2">
                <div class="single-blog mb-30 zoom-img-hover">
                  <div class="blog-img over-hidden">
                    <img
                      class="w-100 img"
                      src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/blog/blog-3.png?raw=true"
                      alt="blog3"
                    />
                  </div>
                  <div class="single-blog-content">
                    <span class="date d-block mb-1">Thurs 27 July – 11:00 – 11:45</span>
                    <h4>
                      <a
                        target="_blank"
                        href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=9646&modtype=tlevent"
                      >
                        Diversity Survey 2023 – How to report your organisation’s diversity data: webinar
                      </a>
                    </h4>
                    <div class="my-btn pt-20">
                      <a
                        target="_blank"
                        href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=9646&modtype=tlevent"
                        class="btn theme-bg text-capitalize f-18 f-700"
                      >
                        Register Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        {/* FIR Resources Section */}
        <div class="home3-service-area home3 position-relative py-5">
          <div class="container py-5">
            <div class="row justify-content-center">
              <div class="col-xl-6  col-lg-7  col-md-10  col-sm-10 col-12">
                <div class="title text-center">
                  <h4 class="f-700">Free FIR Resources</h4>
                  <h6 class="secondary-color f-500 mt-20">New to FIR? Skill up with our learning resources today</h6>
                </div>
              </div>
            </div>
            <div class="home3-service-wrapper">
              <div class="row no-gutters">
                {firIcons.map((icon, i) => (
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div
                      class="home3-single-service-content white-bg  home3-single-service-border transition5 position-relative pt-35 pb-35 pl-45 pr-45
                                "
                      // data-aos="fade-up"
                      // data-aos-duration="2000"
                      // data-aos-delay="10"
                    >
                      <div class="single-service d-flex justify-content-center text-center">
                        <a href={icon.resource} target="_blank">
                          <p className="m-1">{icon.name}</p>
                          <img class="p-1" src={icon.image} alt={`fir icon ${i}`} />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        {/* Footer Section */}
        <div class="footer-area home3 pt-40">
          <div class="footer-border-bottom pt-40"></div>

          <div class="footer-top my-5">
            <div class="container py-5">
              <div class="row">
                <div class="col-xl-6  col-lg-6  col-md-6  col-sm-6 col-12">
                  <div class="f-logo">
                    <img
                      src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/designedHomepage/logo/logo.png?raw=true"
                      alt="logo"
                    />
                  </div>
                </div>
                <div class="col-xl-4  col-lg-4  col-md-4  col-sm-4 col-12 pr-0">
                  <div class="footer-widget f-info pb-30 ml-40 pr-20 mt-25 ">
                    <h6 class="text-capitalize f-700 mb-22">For reporting inquiries please email:</h6>
                    <ul class="footer-info text-decoration-none">
                      <li>
                        <a
                          target="_blank"
                          href="mailto:info@sustainabilitytool.com"
                          class="position-relative d-inline-block mb-2"
                        >
                          info@sustainabilitytool.com
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="mailto:liv.Banfield@actionsustainability.com"
                          class="position-relative d-inline-block mb-2"
                        >
                          liv.Banfield@actionsustainability.com
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="mailto:liban@supplychainschool.co.uk"
                          class="position-relative d-inline-block mb-2"
                        >
                          liban@supplychainschool.co.uk
                        </a>
                      </li>
                    </ul>
                    <h6 class="text-capitalize f-700 mb-22">For partnership inquiries please email:</h6>
                    <ul className="footer-info text-decoration-none">
                      <li>
                        <a
                          target="_blank"
                          href="mailto:charles.naud@actionsustainability.com"
                          class="position-relative d-inline-block mb-2"
                        >
                          charles.naud@actionsustainability.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
