import React from "react";
import Traec from "traec";
import { ActionButton } from "./button";

const respondInvite = (invite, accept) => {
  let status = accept ? "accepted" : "rejected";
  let projectId = invite.getInPath("project_discipline.project.uid");
  let inviteId = invite.get("uid");
  let fetch = new Traec.Fetch("project_invite", "put", { projectId, inviteId });
  fetch.updateFetchParams({
    preFetchHook: (body) => ({ status }),
    postSuccessHook: () => {
      location.reload();
    },
  });
  fetch.dispatch();
};

function AcceptRejectButtons({ invite, isRequest }) {
  if (isRequest) {
    return (
      <ActionButton
        text="Awaiting colleague approval"
        onClickHandler={(e) => {
          alert(
            "This request is pending approval from a colleague.  Contact your colleague or support for assistance in approving this request."
          );
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <ActionButton color="success" text="accept" onClickHandler={(e) => respondInvite(invite, true)} />
      <ActionButton color="danger" text="reject" onClickHandler={(e) => respondInvite(invite, false)} />
    </React.Fragment>
  );
}

export default function ProjectInviteItem(props) {
  let { index: i, invite } = props;
  return (
    <div className="row" key={i} style={{ backgroundColor: (i + 1) % 2 ? "#ddd" : "" }}>
      <div className="col-sm-7">{invite.getIn(["project_discipline", "project", "name"])}</div>
      <div className="col-sm-3">{invite.getIn(["project_discipline", "auth", "name"])}</div>
      <div className="col-sm-2">
        <AcceptRejectButtons {...props} isRequest={invite.get("is_request")} />
      </div>
    </div>
  );
}
