import Moment from "moment";
import { colorScale } from "AppSrc/dashboards/utils";
import React from "react";
import { ReportingPackageStatusItem } from "AppSrc/user/projectItem/reportingPackageStatusItem";

export const RelatedRefs = props => {
  let refs = props.project.get("related_refs");

  if (!refs) {
    return null;
  }

  let projectId = props.project.get("uid");

  return refs.map((ref, i) => {
    let refId = ref.get("uid");
    let refName = ref.get("name");
    let dueDate = ref.getInPath("latest_commit.due_date");
    let dates = "No Due Date";
    let color = "#ddd";

    let status = ref?.getInPath("latest_commit.status.name");
    let isOnHold = status?.startsWith("Not for");

    if (dueDate && !isOnHold) {
      let dueDateStr = Moment(dueDate).format("Do MMM YY");

      let now = Moment(new Date());
      let diffDays = Moment(dueDate).diff(now, "days");
      let diffDayStr = diffDays ? (diffDays > 0 ? `(${diffDays} days)` : `(overdue ${Math.abs(diffDays)} days)`) : null;
      dates = `Due: ${dueDateStr} ${diffDayStr}`;
      color = colorScale(diffDays / 30)
        .brighten(2)
        .hex();
    }

    return (
      <ReportingPackageStatusItem
        index={i}
        key={i}
        projectId={projectId}
        refId={refId}
        refName={refName}
        status={"Pending Input"}
        color={color}
        dates={dates}
      />
    );
  });
};
