import React, { useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import IndicatorRow from "traec-react/equations";
import { baseMetricFields } from "./form";
import { CreateIndicatorForm } from "AppSrc/project/indicators/form";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { setAndShowModal, clearModal } from "AppSrc/utils/modal";

const MODAL_ID = "CommonIndicatorAdminModal001";

const counter = { metric: 0 };

function IndicatorEquation(props) {
  let { show, indicator, basemetrics } = props;
  if (!show) {
    return null;
  }
  return (
    <ErrorBoundary>
      <div className="row mt-3">
        <div className="col-sm-4">
          <b>{"Name"}</b>
        </div>
        <div className="col-sm-2">
          <b>{"Sustainability Issue"}</b>
        </div>
        <div className="col-sm-1">
          <b>{"Factor"}</b>
        </div>
        <div className="col-sm-3">
          <b>{"Numerators & Denominators"}</b>
        </div>
        <div className="col-sm-1">
          <b>Target</b> | <b>Warn</b>
        </div>
        <div className="col-sm-1">
          <b className="float-right">{"Admin"}</b>
        </div>
      </div>
      <IndicatorRow baseMetrics={basemetrics} indicator={indicator} />
    </ErrorBoundary>
  );
}

class BaseIndicatorRow extends React.Component {
  constructor(props) {
    super(props);

    // Get the fetch params to edit this Indicator
    this.state = {
      show_eqn: false,
      showEditForm: false,
      hideIndicatorForm: false
    };

    this.editIndicator = this.editIndicator.bind(this);
    this.mergeIndicators = this.mergeIndicators.bind(this);
    this.recacheIndicator = this.recacheIndicator.bind(this);
  }

  recacheIndicator(e) {
    e.preventDefault();
    let { indicator } = this.props;
    let indicatorId = indicator.get("uid");
    let fetch = new Traec.Fetch("tenant_admin_dispatch", "post");
    fetch.updateFetchParams({
      preFetchHook: data => {
        let post_data = {
          type: "RECACHE_METRIC_CALC_EQN",
          payload: {
            indicatorId
          }
        };
        return post_data;
      },
      postSuccessHook: data => {
        location.reload();
      }
    });
    return fetch.dispatch();
  }

  mergeIndicators(e) {
    e.preventDefault();
    let { indicator } = this.props;
    let indicatorId = indicator.get("uid");
    let fetch = new Traec.Fetch("tenant_admin_dispatch", "post");
    fetch.updateFetchParams({
      preFetchHook: data => ({
        type: "MERGE_INDICATORS",
        payload: {
          from_indicator_id: data.from_indicator_id,
          to_indicator_id: indicatorId
        }
      }),
      postSuccessHook: data => {
        alert(JSON.stringify(data, null, 2));
        location.reload();
      }
    });

    setAndShowModal(MODAL_ID, {
      title: `Merge indicator to ${indicatorId.substring(0, 8)}`,
      body: (
        <BaseFormConnected
          params={fetch.params}
          fields={{
            from_indicator_id: {
              value: "",
              class: "col-sm-12",
              label: "From MetricID",
              helpText: "Note: The ID entered above will be deleted and redirect to this indicator"
            }
          }}
          forceShowForm={true}
          hideUnderline={true}
        />
      )
    });
  }

  editIndicator(e) {
    e.preventDefault();
    let { indicator, basemetrics, dispatch } = this.props;

    setAndShowModal(MODAL_ID, {
      title: `Edit indicator: ${indicator.get("uid").substring(0, 8)}`,
      fullWidth: true,
      immutableBodyProps: true,
      body: (
        <CreateIndicatorForm
          noProject={true}
          indicator={indicator}
          baseMetrics={basemetrics}
          dispatch={dispatch}
          toggleShowHandler={e => {
            $(`#${MODAL_ID}`).modal("hide");
            clearModal(MODAL_ID);
          }}
          fetchHandler={"tenant_admin_indicator"}
          dispatchType={"put"}
        />
      )
    });
  }

  dropDownLinks() {
    return [
      {
        name: `${this.state.show_eqn ? "Hide" : "Show"} Equation`,
        onClick: () => {
          this.setState({ show_eqn: !this.state.show_eqn });
        }
      },
      { name: "Edit", onClick: this.editIndicator },
      { name: "Merge", onClick: this.mergeIndicators },
      { name: "Re-verify Equation", onClick: this.recacheIndicator }
    ];
  }

  render() {
    let { indicator } = this.props;
    let baseMetric = indicator.get("resultBaseMetric");
    let rowNum = counter.metric++;

    return (
      <React.Fragment>
        <div
          className="row"
          style={{ borderTop: "1px solid #ddd", backgroundColor: (rowNum + 1) % 2 ? "#E6E6E6" : "" }}
        >
          <div className="col-sm-1">{indicator.get("uid").substring(0, 8)}</div>
          <div className="col-sm-2">{baseMetric?.get("uid")?.substring(0, 8)}</div>
          <div className="col-sm-6">{baseMetric?.get("name")}</div>
          <div className="col-sm-2">{baseMetric?.get("category")}</div>
          <div className="col-sm-1">{<BSBtnDropdown links={this.dropDownLinks()} />}</div>
        </div>
        <IndicatorEquation {...this.props} show={this.state.show_eqn} />
      </React.Fragment>
    );
  }
}

class BaseIndicatorAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.requiredFetches = [
      new Traec.Fetch("tenant_admin_indicator", "list"),
      new Traec.Fetch("tenant_admin_basemetric", "list")
    ];
  }

  /**********************
     COMPONENT METHODS
   **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { indicators, basemetrics, dispatch } = this.props;

    let rows = indicators
      .sortBy(i =>
        i.getInPath("resultBaseMetric.name")
          ? i.getInPath("resultBaseMetric.name").trim()
          : i.getInPath("resultBaseMetric.name")
      )
      .map((indicator, i) => (
        <BaseIndicatorRow key={i} indicator={indicator} basemetrics={basemetrics} dispatch={dispatch} />
      ));

    return (
      <React.Fragment>
        <h3>Indicators in Tenancy</h3>
        <p>This gives a list of all Indicators within this tenancy</p>

        {/* Row header*/}
        <div className="row" style={{ fontWeight: "bold" }}>
          <div className="col-sm-1">id</div>
          <div className="col-sm-2">resultBaseMetricId</div>
          <div className="col-sm-6">name</div>
          <div className="col-sm-2">issue</div>
          <div className="col-sm-1">admin</div>
        </div>

        {rows}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let indicators = (state.getInPath("entities.metricCalculation.byId") || Traec.Im.Map()).toList();
  let basemetrics = state.getInPath("entities.baseMetrics.byId") || Traec.Im.Map();
  return { indicators, basemetrics };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(BaseIndicatorAdmin);
