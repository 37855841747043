import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { ErrorBoundary } from "traec-react/errors";
import store from "traec/redux/store";

import { AppRouter } from "./app-router";
import DynamicStyle from "./styles";

import ReduxModals from "./utils/modal";
import { ModalProvider } from "storybook-dashboard/components/modal";
import TermsAndConditionsWrapper, { TermsAndConditionsRedirector } from "storybook-dashboard/contracts/terms";
import { getAuthHost } from "storybook-dashboard/auth/utils";
import { AuthContext } from "storybook-dashboard/auth/context";

/* CSS and other such imports for webpack to bundle up */
import "react-tippy/dist/tippy.css";

/*
NOTE: We use HashRouter here because the of issues raised here:
https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writting-manually

With HashRouter approach then all urls sent from the server (as in emails etc.)
should use the site hostname follows to ensure that the hash is included 
before any URL extensions:
https://hostname/#/

It is also possible to use BrowserRouter, but write a catch-all on the
server to redirect all urls to index.html
*/

ReactDOM.render(
  <ErrorBoundary title="Error loading the application">
    <Provider store={store}>
      <DynamicStyle />
      <ModalProvider>
        <BrowserRouter>
          <AuthContext.Provider value={{ host: getAuthHost() }}>
            <TermsAndConditionsRedirector />
            <AppRouter />
          </AuthContext.Provider>
        </BrowserRouter>
      </ModalProvider>
      <ErrorBoundary>
        <ReduxModals />
      </ErrorBoundary>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("app-root")
);
