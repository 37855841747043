import Octicon from "react-octicon";
import { Link } from "react-router-dom";
import React from "react";

export const ReportingPackageStatusItem = props => {
  if (!props.projectId) {
    return null;
  }
  return (
    <React.Fragment>
      <div key={props.index} className="row" style={{ backgroundColor: props.color }}>
        <RefName projectId={props.projectId} refId={props.refId} refName={props.refName} />
        <Status status={props.status} />
        <Dates dates={props.dates} />
        <GoToReport projectId={props.projectId} refId={props.refId} commitId={props.commitId} />
      </div>
    </React.Fragment>
  );
};

const RefName = props => {
  let { projectId, refId, refName } = props;
  projectId = projectId ? projectId.substring(0, 8) : projectId;
  refId = refId ? refId.substring(0, 8) : refId;
  return (
    <div className="col-sm-5">
      <div className="ml-3">
        <Octicon name="file" />
        &nbsp;&nbsp;
        <Link to={`/project/${projectId}/wpack/${refId}/`}>{refName}</Link>
      </div>
    </div>
  );
};

const Status = props => {
  return (
    <div className="col-sm-2">
      <i>{props.status}</i>
    </div>
  );
};

const Dates = props => {
  return <div className="col-sm-3">{props.dates}</div>;
};

const GoToReport = props => {
  let { projectId, refId, commitId } = props;
  projectId = projectId ? projectId.substring(0, 8) : projectId;
  refId = refId ? refId.substring(0, 8) : refId;
  commitId = commitId ? commitId.substring(0, 8) : commitId;
  return (
    <div className="col-sm-2">
      <Link className="float-right" to={`/project/${projectId}/wpack/${refId}/report/${commitId ? commitId : ""}`}>
        Go to Report
      </Link>
    </div>
  );
};
