import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { Spinner } from "AppSrc/login-redirect";
import { StaticContent } from "./static";
import ExternalContentHomePage from "./external";

function Home(props) {
  let { isAuthenticated } = props;
  // Hard-coded redirect for uk.sustainabilitytool.com
  if (location.hostname.includes("uk.sustainabilitytool.com")) {
    window.location.assign("https://www.sustainabilitytool.com");
    return null;
  }

  let { tenantData, externalContent } = props;

  if (!tenantData) {
    return <Spinner />;
  }

  // Render a page that has external content
  if (externalContent) {
    console.log("Rendering home page with externalContent:", externalContent?.toJS());
    //return <p>external content</p>;
    return <ExternalContentHomePage {...props} />;
  }

  // Finally return default StaticContent if nothing else
  console.log("Rendering home page with staticContent");
  return <StaticContent isAuthenticated={isAuthenticated} />;
}

const mapStateToProps = state => {
  let tenantData = state.getInPath("entities.tenant");
  let externalContent = tenantData?.getInPath("meta_json.home_sources");
  let authStatus = state.getInPath("auth.status");
  let isAuthenticated = state.getInPath("auth.isAuthenticated");

  return { tenantData, externalContent, authStatus, isAuthenticated };
};

export default connect(mapStateToProps)(Home);
