import React from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import { subTenantFields } from "./form";

const counter = { metric: 0 };

class SubTenantRow extends React.Component {
  constructor(props) {
    super(props);

    // Get the fetch params to edit this BaseMetric
    this.state = {};

    this.deleteTenant = this.deleteTenant.bind(this);
  }

  deleteTenant(e) {
    e.preventDefault();
    let { tenant } = this.props;
    let tenantId = tenant.get("uid");
    let fetch = new Traec.Fetch("tenant_admin_tenant", "delete", { tenantId });
    fetch.updateFetchParams({
      postSuccessHook: () => location.reload()
    });
    fetch.dispatch();
  }

  dropDownLinks() {
    return [{ name: "Delete", onClick: this.deleteTenant }];
  }

  render() {
    let { tenant } = this.props;
    if (!tenant || !tenant.get("uid")) {
      return null;
    }

    let rowNum = counter.metric++;
    let parentTenantId = tenant.get("parent_tenant");
    return (
      <React.Fragment>
        <div
          className="row"
          style={{ borderTop: "1px solid #ddd", backgroundColor: (rowNum + 1) % 2 ? "#E6E6E6" : "" }}
        >
          <div className="col-sm-2">{tenant.get("uid").substring(0, 8)}</div>
          <div className="col-sm-3">{tenant.get("domain_url")}</div>
          <div className="col-sm-3">{tenant.get("schema_name")}</div>
          <div className="col-sm-3">{tenant.getInPath("admin_company.name")}</div>
          <div className="col-sm-1">{<BSBtnDropdown links={this.dropDownLinks()} />}</div>
        </div>
      </React.Fragment>
    );
  }
}

class SubTenantAdmin extends React.Component {
  constructor(props) {
    super(props);

    let fetch = new Traec.Fetch("tenant_admin_tenant", "post");

    this.state = {
      formParams: fetch.params
    };

    this.requiredFetches = [new Traec.Fetch("tenant_admin_tenant", "list")];

    this.createSubTenancy = this.createSubTenancy.bind(this);
  }

  /**********************
     COMPONENT METHODS
   **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  createSubTenancy(e) {
    e.preventDefault();
    let fetch = new Traec.Fetch("tenant_admin_tenant", "post");
    fetch.toggleForm();
  }

  render() {
    let { tenants } = this.props;

    let rows = tenants.map((t, i) => <SubTenantRow key={i} tenant={t} />);

    return (
      <React.Fragment>
        <h3>Sub-Tenancies</h3>
        <p>
          Sub-tenancies are tenants that are isolated with regards to the MetricTree, Reporting Packages and Reports.
        </p>

        <p>
          "Safe" operations in tenancies that will not affect other tenancies (including this parent tenancy) include:
        </p>
        <ul>
          <li>Editing of BaseMetrics</li>
          <li>Reporting input values</li>
          <li>Deleting Reports and Reporting Packages</li>
        </ul>

        <p>
          <b>Notes:</b>
        </p>
        <ul>
          <li>
            Company and Project objects that are created are NOT isolated within tenancies. These are in a commonly
            accessibly space. This is because a tenant may wish to have "live" business units, projects and data
            alongside "sandboxed/demo" projectsand data.
          </li>
          <li>
            By default the new Tenancy that is created will be empty (no BaseMetrics, Reporting Packages or Reports).
          </li>
          <li>
            Specify a "From Tenancy" when creating a new Tenancy to clone data from another Tenancy into the new
            Tenancy. This allows for creation of a "template" tenancy with a default/initial set of data.
          </li>
          <li>
            Specify a "From Company" when creating a new Tenancy to clone existing data from a Corporate tree in the
            "From Tenancy" into the new sub-tenancy.
          </li>
        </ul>

        <button className="btn btn-primary btn-sm mt-3 mb-5" onClick={this.createSubTenancy}>
          Create a new Sub-Tenancy
        </button>
        <div className="row">
          <BaseFormConnected params={this.state.formParams} fields={subTenantFields} />
        </div>

        {/* Row header*/}
        <div className="row" style={{ fontWeight: "bold" }}>
          <div className="col-sm-2">id</div>
          <div className="col-sm-3">domain_url</div>
          <div className="col-sm-3">schema_name</div>
          <div className="col-sm-3">admin_company</div>
          <div className="col-sm-1">admin</div>
        </div>

        {rows}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let tenants = (state.getInPath("entities.tenants.byId") || Traec.Im.Map()).toList();
  return { tenants };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(SubTenantAdmin);
