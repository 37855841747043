import React from "react";

export function ActionButton({ text, color, onClickHandler }) {
  return (
    <span
      className={`bg-${color || "primary"} rounded m-1 pl-1 pr-1 text-white`}
      style={{ cursor: "pointer" }}
      onClick={onClickHandler}
    >
      {text}
    </span>
  );
}
